import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="utility"
export default class extends Controller {
  select(event) {
    let category = event.target.value;
    let allCategories = document.querySelectorAll(".dropdownCategories");

    allCategories.forEach((el) => {
      if (category === "all_categories") {
        el.classList.remove("hidden");
      } else {
        if (el.classList.contains(category)) {
          el.classList.remove("hidden");
        } else {
          el.classList.add("hidden");
        }
      }
    });
  }
}

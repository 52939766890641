import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="images"
export default class extends Controller {
  static targets = ['logoPreview', 'coverImagePreview'];

  preview(e) {
    if (e.target.files.length > 0) {
      const src = URL.createObjectURL(e.target.files[0]);
      e.target.insertAdjacentHTML(
        'afterend',
        `<div class="mt-5 w-40"><img src="${src}" class="rounded-xl"/></div>`
      );
    }
  }
}

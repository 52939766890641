import "@hotwired/turbo-rails";
import "./controllers";

window.initMap = () => {
  const event = new Event("MapLoaded");
  event.initEvent("mapLoaded", true, true);
  document.dispatchEvent(event);
};

document.addEventListener("turbo:load", function (event) {
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  gtag("js", new Date());

  gtag("config", "G-VG2EKRL2FT");
});

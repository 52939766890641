import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="username-availability"

export default class extends Controller {
  static targets = ['username', 'message'];
  static debounceTimeout = null;

  connect() {}

  async checkUsername() {
    let username = this.usernameTarget.value;

    if (username.length >= 3) {
      clearTimeout(this.constructor.debounceTimeout);
      this.constructor.debounceTimeout = setTimeout(async () => {
        try {
          let response = await fetch(`/check_username?username=${username}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')
                .content,
            },
          });
          let data = await response.json();

          if (data.available) {
            this.messageTarget.textContent = `✅  The username '${username}' is available.`;
            this.messageTarget.classList.remove('text-red-500');
            this.messageTarget.classList.add('text-green-500');
          } else {
            this.messageTarget.textContent =
              data.message ||
              `❌  The username '${username}' is not available.`;
            this.messageTarget.classList.remove('text-green-500');
            this.messageTarget.classList.add('text-red-500');
          }
        } catch (error) {
          console.error(error);
          this.messageTarget.textContent =
            'An error occurred while checking the username availability.';
        }
      }, 500);
    } else {
      this.messageTarget.textContent = '';
      this.messageTarget.classList.remove('text-green-500');
      this.messageTarget.classList.remove('text-red-500');
    }
  }
}

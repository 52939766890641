import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  // define our targets in our controller
  static targets = ['source', 'copyButton'];

  connect() {}

  // define our copy action

  restoreCopyButton() {
    const btnTarget = this.copyButtonTarget;
    btnTarget.children[2].innerText = 'Copy Link';
    btnTarget.children[1].classList.toggle('hidden');
    btnTarget.children[0].classList.toggle('hidden');
    btnTarget.classList.remove('text-green-600');
  }

  copy() {
    const btnTarget = this.copyButtonTarget;
    navigator.clipboard.writeText(this.sourceTarget.value);
    btnTarget.children[2].innerText = 'Copied!';
    btnTarget.children[1].classList.toggle('hidden');
    btnTarget.children[0].classList.toggle('hidden');

    btnTarget.classList.add('text-green-600');
    setTimeout(this.restoreCopyButton.bind(this), 3000);
  }
}

import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="places"
export default class extends Controller {
  static targets = [
    "field",
    "map",
    "address",
    "city",
    "country",
    "url",
    "lat",
    "lng",
    "placeId",
  ];

  connect() {
    if (window.google) {
      this.initGoogleMap();
    }
  }

  initGoogleMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: { lat: 0, lng: 0 },
      zoom: 1,
      draggable: true,
    });

    // Pre fill map with existing lat/lng
    const lat = parseFloat(this.latTarget.value);
    const lng = parseFloat(this.lngTarget.value);

    if (lat && lng) {
      this.map.setCenter({ lat: lat, lng: lng });
      this.map.setZoom(17);

      this.marker = new google.maps.Marker({
        map: this.map,
        anchorPoint: new google.maps.Point(0, -29),
        draggable: true,
      });

      this.marker.setPosition({
        lat: lat,
        lng: lng,
      });
      this.marker.setVisible(true);
    }

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, {
      fields: [
        "geometry",
        "place_id",
        "url",
        "formatted_address",
        "name",
        "address_components",
      ],
      types: ["establishment"],
    });

    this.autocomplete.bindTo("bounds", this.map);
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(0, -29),
      // draggable: true,
    });

    // this.marker.addListener("dragend", this.markerDragged.bind(this));
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();
    // console.log(place);
    if (!place.geometry) {
      window.alert("No details available for input: '" + place.name + "'");
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    this.marker.setPosition(place.geometry.location);
    this.marker.setVisible(true);

    if (place.geometry.location) {
      this.latTarget.value = place.geometry.location.lat();
      this.lngTarget.value = place.geometry.location.lng();
    }

    // Pre fill form fields
    this.fieldTarget.value = place?.name;
    this.addressTarget.value = place?.formatted_address;
    this.placeIdTarget.value = place?.place_id;
    if (this.hasUrlTarget) this.urlTarget.value = place?.url;

    for (const component of place.address_components) {
      const componentType = component.types[0];

      if (componentType === "locality" && this.hasCityTarget) {
        this.cityTarget.value = component.long_name;
      }

      if (componentType === "country" && this.hasCountryTarget) {
        this.countryTarget.value = component.long_name;
      }
    }
  }

  markerDragged(e) {
    this.latTarget.value = e.latLng.lat();
    this.lngTarget.value = e.latLng.lng();
  }

  keydown(e) {
    if (e.key == "Enter") {
      e.preventDefault();
    }
  }
}

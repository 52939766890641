import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['details'];
  connect() {}

  checkAccordeon(event) {
    this.detailsTargets.forEach((detail) => {
      if (detail !== event.target) {
        detail.removeAttribute('open');
      }
    });
  }
}

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['link', 'navbar'];

  connect() {
    this.checkActive();
  }

  checkActive() {
    this.linkTargets.forEach((link) => {
      let url = link.attributes.href.value;
      let currentPath = window.location.pathname;
      if (currentPath === url) {
        link.classList.replace('text-gray-300', 'text-white');
      } else {
        link.classList.replace('text-white', 'text-gray-300');
      }
    });
  }

  // add dropshadow to navbar when scrolling
  handleScroll() {
    if (window.scrollY > 0) {
      this.navbarTarget.classList.add('drop-shadow-nav');
    } else {
      this.navbarTarget.classList.remove('drop-shadow-nav');
    }
  }
}

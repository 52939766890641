import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'sharelink',
    'sharelinkWrapper',
    'exampleletter',
    'exampleletterWrapper',
    'video',
    'videoWrapper',
    'qrprint',
    'qrprintWrapper',
    'introductionexample',
    'introductionexampleWrapper',
  ];
  static values = { isOpen: { type: Boolean, default: false } };

  sharelinkShow() {
    this.sharelinkTarget.classList.remove('hidden');
    this.isOpenValue = true;
  }

  sharelinkhide() {
    this.sharelinkTarget.classList.add('hidden');
    this.isOpenValue = false;
  }

  sharelinkOutClick(event) {
    if (this.isOpenValue && event.target === this.sharelinkWrapperTarget) {
      this.sharelinkhide();
    }
  }

  exampleletterShow() {
    this.exampleletterTarget.classList.remove('hidden');
    this.isOpenValue = true;
  }

  exampleletterHide() {
    this.exampleletterTarget.classList.add('hidden');
    this.isOpenValue = false;
  }

  exampleletterOutClick(event) {
    if (this.isOpenValue && event.target === this.exampleletterWrapperTarget) {
      this.exampleletterHide();
    }
  }

  videoShow() {
    this.videoTarget.classList.remove('hidden');
    this.isOpenValue = true;
  }

  videoHide() {
    this.videoTarget.classList.add('hidden');
    this.isOpenValue = false;
  }

  videoOutClick(event) {
    if (this.isOpenValue && event.target === this.videoWrapperTarget) {
      this.videoHide();
    }
  }

  qrprintShow() {
    this.qrprintTarget.classList.remove('hidden');
    this.isOpenValue = true;
  }

  qrprintHide() {
    this.qrprintTarget.classList.add('hidden');
    this.isOpenValue = false;
  }

  qrprintOutClick(event) {
    if (this.isOpenValue && event.target === this.qrprintWrapperTarget) {
      this.qrprintHide();
    }
  }

  introductionexampleShow() {
    this.introductionexampleTarget.classList.remove('hidden');
    this.isOpenValue = true;
  }

  introductionexampleHide() {
    this.introductionexampleTarget.classList.add('hidden');
    this.isOpenValue = false;
  }

  introductionexampleOutClick(event) {
    if (
      this.isOpenValue &&
      event.target === this.introductionexampleWrapperTarget
    ) {
      this.introductionexampleHide();
    }
  }

  connect() {}
}

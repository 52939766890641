import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="flash"
export default class extends Controller {
  connect() {
    // hide flash message after 3 seconds
    setTimeout(() => {
      this.element.classList.add("transition-all", "opacity-0", "h-0");
      this.element.remove();
    }, 2000);
  }

  // remove flash message on click
  hide = () => {
    this.element.classList.add("transition-all", "opacity-0", "h-0");
    this.element.remove();
  };
}
